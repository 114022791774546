import type { IWixWindow } from '@wix/yoshi-flow-editor';

export class WarmupDataManager {
  constructor(private warmupData: IWixWindow['warmupData'], private isSSR: boolean) {
    this.manageData = this.manageData.bind(this);
  }

  async manageData<T>(callback: () => Promise<T>, key: string) {
    let res: T | undefined;

    if (!this.isSSR) {
      res = this.warmupData.get(key);
    }
    if (!res) {
      res = await callback();
      if (this.isSSR) {
        this.warmupData.set(key, res);
      }
    }

    return res;
  }
}
