import type { CreateCurrencyFormatterArgs, ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import type { PriceFormatterFn } from 'root/clientTypes';

const MAX_FRACTION_DIGITS = 3;

export const getPriceFormatter = (flowAPI: ControllerFlowAPI): PriceFormatterFn => {
  const {
    controllerConfig,
    getCurrencyFormatter,
    environment: { multilingual },
  } = flowAPI;
  let locale: string | undefined;
  const currencyCode = controllerConfig.wixCodeApi.site.currency;

  const siteLanguageFallback = controllerConfig.wixCodeApi.site.language || 'en';
  const siteCountryFallback = 'us';
  const siteCurrencyFallback = 'USD';

  if (multilingual?.isEnabled && multilingual?.currentLanguage) {
    locale = multilingual.siteLanguages.find((lang) => lang.languageCode === multilingual.currentLanguage)?.locale;
  } else {
    locale = controllerConfig.wixCodeApi.site.regionalSettings;
  }

  const [siteLanguage, siteCountry] = locale?.split('-') || '';

  return ({ price, shouldDisplayCurrency = true }: { price: string; shouldDisplayCurrency?: boolean }) => {
    let formatterProps: CreateCurrencyFormatterArgs<boolean> = {
      country: siteCountry || siteCountryFallback,
      language: siteLanguage || siteLanguageFallback,
      parts: !shouldDisplayCurrency || !currencyCode,
    };

    const [, fractionalPart = ''] = price.split('.');

    let minimumFractionDigits = 0;
    if (fractionalPart.length > 0) {
      minimumFractionDigits = Math.min(MAX_FRACTION_DIGITS, fractionalPart.length);
    }

    formatterProps = {
      ...formatterProps,
      minimumFractionDigits,
      maximumFractionDigits: MAX_FRACTION_DIGITS,
    };

    const formattedPrice = getCurrencyFormatter(formatterProps)({
      currency: currencyCode || siteCurrencyFallback,
      value: price,
    });

    if (shouldDisplayCurrency && currencyCode) {
      return formattedPrice as string;
    }

    return (formattedPrice as Intl.NumberFormatPart[]).reduce((stringPrice, part) => {
      if (part.type !== 'currency') {
        return stringPrice + part.value;
      }
      return stringPrice;
    }, '');
  };
};
