import type { StyleParamKeysByType } from './components/Menus/stylesParams';
import type { BorderPosition } from './components/Menus/Settings/components/BaseBorderPicker/BaseBorderPicker';
import type { StyleParamType } from '@wix/tpa-settings';
import type { Section as _Section, Menu as _Menu } from 'root/apiTypes';

export type PriceFormatterFn = ({
  price,
  shouldDisplayCurrency,
}: {
  price: string;
  shouldDisplayCurrency?: boolean;
}) => string;

export type PageData = {
  pageId: string;
  title: string;
  widgetId: string;
  desktopPresetId?: string;
  mobilePresetId?: string;
  pageUriSEO: string;
};

export type NavigateToParams = { currentUrl: string; isFirst: boolean; menuId?: string; urlQueryParam?: string };
export type NavigateToFn = (navigateToParams: NavigateToParams) => void;

export enum DisplayMenus {
  All = 'all',
  Specific = 'specific',
}

export type BorderPositionType = (typeof BorderPosition)[keyof typeof BorderPosition];
export type BorderStyleParamsMap<T extends StyleParamType> = Record<
  Exclude<BorderPositionType, 'all'>,
  StyleParamKeysByType<T>
>;

export enum DisplayTabEntity {
  MultiMenus = 'multi-menus',
  Menu = 'menu',
  Section = 'section',
  Item = 'item',
  Variant = 'variant',
  Dots = 'dots',
}

export enum DisplayTabDrillInItems {
  PricingOptions = 'pricingOptions',
  ImagePlaceholder = 'imagePlaceholder',
  SideImage = 'sideImage',
}

export enum DisplayTabImageFields {
  SelectImage = 'selectImage',
  ChooseMenus = 'chooseMenus',
}

export type DisplayTabFields = DisplayTabEntity | DisplayTabDrillInItems | DisplayTabImageFields;

export enum LayoutTabDrillInItems {
  MenuBackground = 'menuBackground',
  ItemImage = 'itemImage',
}

export type Range = { min: number; max: number };
export type MenusPopulationLevel = { sections: boolean; items: boolean };

export const MENUS_POPULATION_LEVEL_ALL: MenusPopulationLevel = {
  sections: true,
  items: true,
};

export type Section = Pick<_Section, 'id' | 'name'>;
export type MenuWithSections = Pick<
  _Menu,
  'id' | 'name' | 'visible' | 'businessLocationId' | 'businessLocationDetails'
> & { sections: Section[] };

export type MenusWithSections = MenuWithSections[];

export type CheckboxElement = { id: string; name: string; checked: boolean };
export type MenuCheckbox = CheckboxElement & { sections: CheckboxElement[] };

export enum DesignTabEntity {
  Locations = 'locations',
  Navigation = 'navigation',
  Menus = 'menus',
  Sections = 'sections',
  Items = 'items',
  Variants = 'variants',
  Dots = 'dots',
  Labels = 'labels',
}
