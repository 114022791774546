import type { HttpResponse } from '@wix/yoshi-flow-editor';
import type { IApiError } from 'root/api/utils/types';

type ApiErrorType = 'network' | 'api' | 'unknown';

export class ApiError {
  private response: HttpResponse | undefined;

  constructor(private error?: Error | IApiError) {
    this.response = (error as IApiError).response;
  }

  get message(): string {
    return this.response?.data?.message ?? this.response?.data?.error ?? (this.error as Error).message;
  }

  get requestId() {
    // this.requestId is undefined, not sure why
    return this.response?.headers?.['x-wix-request-id'];
  }

  get status() {
    return this.response?.status ?? undefined;
  }

  get statusText() {
    return this.response?.statusText ?? 'unknown';
  }

  private get type(): ApiErrorType {
    if (!this.error) {
      return 'unknown';
    }
    if (!this.response) {
      return 'network';
    }
    return 'api';
  }

  get shouldBeMonitored() {
    return this.type === 'api';
  }

  get shouldNotIncludeInPanoramaMetrics() {
    return notIncludedInPanoramaMetricsErrors.includes(this.status as number);
  }

  get asException(): Error {
    if (this.error instanceof Error) {
      return this.error;
    }
    return new Error(this.message);
  }
}

export const notIncludedInPanoramaMetricsErrors = [401, 403, 404];
