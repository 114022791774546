import type { BaseShowcasePopulatedItem, ShowcasePopulationLevel } from 'root/apiTypes';
import type { HttpClientFactory } from './utils/types';
import { PopulatedItemClientFactory } from '@wix/restaurants-populated-item-client/web';
import { SHOWCASE_POPULATION_LEVEL } from 'root/consts';
import type { ApiCallResult, GetBulkResponse, PopulatedItem } from '@wix/restaurants-populated-item-client/types';

export const PopulatedItemsClient: HttpClientFactory<BaseShowcasePopulatedItem> = (httpClient, errorHandler) => {
  const populatedItemsClient = PopulatedItemClientFactory<ShowcasePopulationLevel>({
    httpClient,
    populationLevel: SHOWCASE_POPULATION_LEVEL,
  });
  return {
    get: ({ id }) => {
      const callback = async () => {
        const response = await populatedItemsClient.getById(id);

        if (response.hasError) {
          throw response.error;
        }

        return { data: response.data };
      };

      return errorHandler?.withErrorHandler
        ? errorHandler.withErrorHandler(callback, { errorCodesMap: {} })
        : callback();
    },
    getAll: ({ ids, paging, optimizedBulk = false }) => {
      const callback = async () => {
        let response: ApiCallResult<GetBulkResponse<PopulatedItem<ShowcasePopulationLevel>>>;

        if (optimizedBulk) {
          response = await populatedItemsClient.getOptimizedBulk({ ids, paging, onlyVisible: true });
        } else {
          const filter = { visible: { $eq: true } };
          response = await populatedItemsClient.getBulk({ ids, paging, filter });
        }

        if (response.hasError) {
          throw response.error;
        }

        return { data: response.data.data, pagingMetadata: response.data.pagingMetadata };
      };
      return errorHandler?.withErrorHandler
        ? errorHandler.withErrorHandler(callback, { errorCodesMap: {} })
        : callback();
    },
  };
};
