import type { PopulatedMenu, Menu, Section, ShowcasePopulatedItem, PopulatedSection } from 'root/apiTypes';
import type { MenusPopulationLevel } from 'root/clientTypes';
import { MENUS_POPULATION_LEVEL_ALL } from 'root/clientTypes';

export const populateMenu = (
  menu: Menu,
  sections: Section[],
  populatedItems: ShowcasePopulatedItem[],
  populationLevel: MenusPopulationLevel = MENUS_POPULATION_LEVEL_ALL
): PopulatedMenu => {
  const menuSections = menu.sectionIds
    ?.map((sectionId) => sections.find((section) => section.id === sectionId))
    ?.filter((section) => !!section);

  const sectionsWithItems = menuSections?.map((section) => {
    const sectionItems = populationLevel.items
      ? (section?.itemIds
          ?.map((itemId) => populatedItems.find((item) => item.id === itemId))
          ?.filter((item) => !!item && item.visible !== false) as ShowcasePopulatedItem[])
      : [];

    return {
      ...section,
      items: sectionItems,
    };
  }) as PopulatedSection[];

  return {
    ...menu,
    sections: sectionsWithItems,
  };
};
