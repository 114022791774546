import type { PopulatedMenu, ShowcasePopulatedItem } from 'root/apiTypes';
import type { HttpClientFactory } from './utils/types';
import { MenusClient } from './MenusClient';
import { SectionsClient } from './SectionsClient';
import { PopulatedItemsClient } from './ItemsClient';
import { populateMenu } from 'root/api/utils/populateMenu';
import { completeMissingEntities } from 'root/api/utils/completeMissingEntities';

export const PopulatedMenuClient: HttpClientFactory<PopulatedMenu> = (httpClient, errorHandler) => {
  const MenusApi = MenusClient(httpClient, errorHandler);
  const SectionsApi = SectionsClient(httpClient, errorHandler);
  const PopulatedItemsApi = PopulatedItemsClient(httpClient, errorHandler);

  return {
    getAll: async ({ paging, optimizedBulk = false, onlyVisible }) => {
      const handleError = (e: Error) => {
        if (errorHandler?.getResolvedError) {
          errorHandler.getResolvedError(e);
        }

        throw e;
      };

      const [menus, sections, populatedItems] = await Promise.all([
        MenusApi.getAll({ paging, onlyVisible }).catch(handleError),
        SectionsApi.getAll({ paging }).catch(handleError),
        PopulatedItemsApi.getAll({ paging, optimizedBulk }).catch(handleError),
      ]);

      await completeMissingEntities({
        menus: menus.data,
        sections,
        getSections: (...args) => SectionsApi.getAll(...args).catch(handleError),
        populatedItems,
        getPopulatedItems: (...args) => PopulatedItemsApi.getAll(...args).catch(handleError),
        maxNumberOfEntitiesToLoad: paging?.limit,
        optimizedBulk,
      });

      return {
        data: menus.data.map((menu) =>
          populateMenu(menu, sections.data, (populatedItems?.data ?? []) as ShowcasePopulatedItem[])
        ) as PopulatedMenu[],
      };
    },
  };
};
