import { useFedopsLogger, useSentry } from '@wix/yoshi-flow-editor';
import { settleApiCall } from 'root/api/utils/settleApiCall';
import type { Interaction } from 'root/utils/monitoring/types';

type MonitoringTools = {
  sentry: ReturnType<typeof useSentry>;
  fedopsLogger: ReturnType<typeof useFedopsLogger>;
};

export const useMonitoredApiCall = () => {
  const fedopsLogger = useFedopsLogger();
  const sentry = useSentry();

  return getMonitoredApiCall({ fedopsLogger, sentry });
};

export const getMonitoredApiCall = ({ fedopsLogger, sentry }: MonitoringTools) => {
  return async <T>(callback: () => Promise<T>, interaction: Interaction) => {
    fedopsLogger?.interactionStarted(interaction);
    const { data, error } = await settleApiCall<T>(callback);
    fedopsLogger?.interactionEnded(interaction);

    if (error) {
      sentry?.captureException(error.asException);
    }

    return { data, error };
  };
};
