export const getIdsChunks = (array: string[], size: number): string[][] => {
  const result = array.reduce((resultArray: string[][], id, index) => {
    const chunkIndex = Math.floor(index / size);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }
    resultArray[chunkIndex].push(id);
    return resultArray;
  }, []);
  return result;
};
