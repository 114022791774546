import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { PopulatedMenuClient } from 'root/api/PopulatedMenuClient';
import { getMonitoredApiCall } from 'root/api/utils/monitoredApiCall';
import type { ApiCallResult, GetAllResponse } from 'root/api/utils/types';
import type { PopulatedMenu } from 'root/apiTypes';
import { getPriceFormatter } from 'root/utils/currency';
import { getMenuToDisplayFromUrl, goToPage } from 'root/utils/navigation';
import { WarmupDataManager } from 'root/utils/WarmupDataManager';
import { restaurantsUouPageFinishedLoading } from '@wix/bi-logger-restaurants-data/v2';
import { getHostingEnvironment, getIsCurrentPageMenusDefaultPage } from 'root/utils/bi';
import MenuWidget from 'root/components/Menus/.component.json';
import { MENU_WIDGET_NAME, PAGE_NAME } from 'root/consts';
import { getPopulatedMenusWithFormattedPrice } from 'root/utils/getPopulatedMenusWithFormattedPrice';
import settingsParams from './settingsParams';
import { sendSEOData } from 'root/utils/seo';
import type { MenuOrder } from './Settings/Tabs/MenusTab/MenusSettingsParams';
import type { ControllerProps } from './Widget/Widget';
import { getMenusToDisplay } from 'root/components/Menus/Widget/utils';
import type { MenusDisplayOptions } from './Settings/Tabs/MenusTab/consts';
import { getLocations } from 'root/utils/location';

let menusPromise: ApiCallResult<GetAllResponse<PopulatedMenu>> | undefined;

const createController: CreateControllerFn = ({ flowAPI }) => {
  const priceFormatter = getPriceFormatter(flowAPI);
  const navigateTo = goToPage(flowAPI);

  const warmupData = new WarmupDataManager(
    flowAPI.controllerConfig.wixCodeApi.window.warmupData,
    flowAPI.environment.isSSR
  );
  const { environment, settings, experiments } = flowAPI;
  const { site, location, seo } = flowAPI?.controllerConfig?.wixCodeApi;
  const errorHandler = flowAPI.essentials.errorHandler;
  const monitoredApiCall = getMonitoredApiCall({
    fedopsLogger: flowAPI.fedops,
    sentry: flowAPI.sentry,
  });
  const isGetOptimizedBulkExperimentEnabled = experiments.enabled('specs.restaurants.editorGetOptimizedBulk');
  const isMultiLocationExperimentEnabled = experiments.enabled('specs.restaurants.menusLivesiteMultiLocation');
  const getMonitoredPopulatedMenuClient = () =>
    monitoredApiCall(
      () =>
        PopulatedMenuClient(flowAPI.essentials.httpClient, errorHandler).getAll({
          optimizedBulk: isGetOptimizedBulkExperimentEnabled,
        }),
      'get-populate-menus'
    );

  if (!menusPromise) {
    menusPromise = warmupData.manageData(getMonitoredPopulatedMenuClient, 'populatedMenus');
  }
  return {
    async pageReady() {
      const isCurrentPageMenusDefaultPage = await getIsCurrentPageMenusDefaultPage(site);
      const menusDisplayOrder = settings.get(settingsParams.menusDisplayOrder!) as MenuOrder[];
      const menusDisplayOption = settings.get(settingsParams.menusDisplayOption) as MenusDisplayOptions;

      let activeMenu;

      if (menusPromise) {
        try {
          const { data: menus, error: menusError } = (await menusPromise) || {};

          if (menusError) {
            throw menusError.asException;
          }

          const populatedMenus = menus?.data ? getPopulatedMenusWithFormattedPrice(menus.data, priceFormatter) : [];
          const displayableMenus = !menusDisplayOrder
            ? populatedMenus
            : getMenusToDisplay({ menusDisplayOrder, menus: populatedMenus, menusDisplayOption });

          const { activeTab, initialLocation } = getMenuToDisplayFromUrl({
            urlQuery: location.query,
            displayableMenus,
            isMultiLocationExperimentEnabled,
          });
          activeMenu = displayableMenus[activeTab];
          const controllerProps: ControllerProps = {
            menus: populatedMenus,
            locations: getLocations(populatedMenus),
            navigateTo,
            url: location.url,
            fitToContentHeight: true,
            initialActiveTab: activeTab,
            initialLocation,
          };
          flowAPI.controllerConfig.setProps(controllerProps);

          if (activeMenu) {
            try {
              sendSEOData({
                baseUrl: location.baseUrl,
                siteUrl: site.currentPage?.url ?? '',
                isCurrentPageMenusDefaultPage,
                multilingual: environment.multilingual,
                menusDisplayOrder,
                menus: menus?.data || [],
                seo,
                activeMenu,
              });
            } catch (e) {}
          }
        } catch (e) {
          const resolvedError = errorHandler?.getResolvedError(e);

          const controllerProps: ControllerProps = {
            menus: [],
            locations: {},
            navigateTo,
            url: location.url,
            fitToContentHeight: true,
            initialActiveTab: 0,
            messageError: resolvedError?.message,
          };
          flowAPI.controllerConfig.setProps(controllerProps);
        }
      }

      flowAPI.bi?.report(
        restaurantsUouPageFinishedLoading({
          hosting: getHostingEnvironment(flowAPI.environment),
          pageName: PAGE_NAME,
          product: PAGE_NAME,
          widgetId: MenuWidget.id,
          widgetInstanceId: flowAPI.controllerConfig.compId,
          widgetName: MENU_WIDGET_NAME,
          isMenusDefaultPage: isCurrentPageMenusDefaultPage,
          isOoi: true,
          menuId: activeMenu?.id,
          locationId: activeMenu?.businessLocationId,
        })
      );
    },
  };
};

// eslint-disable-next-line import/no-default-export
export default createController;
