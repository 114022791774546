import type { PopulatedMenu } from 'root/apiTypes';
import type { PriceFormatterFn } from 'root/clientTypes';

export const getPopulatedMenusWithFormattedPrice = (menus: PopulatedMenu[], priceFormatter: PriceFormatterFn) =>
  menus.map((menu) => ({
    ...menu,
    sections: menu.sections.map((section) => ({
      ...section,
      items: section.items?.map((item) => {
        let priceInfo = item.priceInfo;
        let priceVariants = item.priceVariants;
        if (priceInfo) {
          const price = priceInfo?.price ?? '';
          priceInfo = {
            ...priceInfo,
            formattedPrice: priceFormatter?.({ price, shouldDisplayCurrency: true }),
            formattedPriceNoCurrency: priceFormatter?.({ price, shouldDisplayCurrency: false }),
          };
        } else if (priceVariants?.variants) {
          priceVariants = {
            variants: priceVariants?.variants?.map((variant) => {
              const price = variant.priceInfo?.price ?? '';
              return {
                ...variant,
                priceInfo: {
                  ...variant.priceInfo,
                  formattedPrice: priceFormatter?.({ price, shouldDisplayCurrency: true }),
                  formattedPriceNoCurrency: priceFormatter?.({ price, shouldDisplayCurrency: false }),
                },
              };
            }),
          };
        }
        return { ...item, priceInfo, priceVariants };
      }),
    })),
  }));
