import { ApiError } from 'root/utils/errors';
import type { ApiCallResult, IApiError } from './types';

export const settleApiCall = async <T>(callback: () => Promise<T>): ApiCallResult<T> => {
  let error: ApiError | undefined;
  let data: T | undefined;

  try {
    data = await callback();
  } catch (callError) {
    error = new ApiError(callError as Error | IApiError);
  }
  return { data, error };
};
